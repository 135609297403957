/*
    Module voor localconfig
 */

import {APP_INITIALIZER, NgModule} from "@angular/core";
import {localConfig} from "./localconfig";
import {HttpClientModule} from "@angular/common/http";

/**
 * APP_INITIALIZER functie voor het laden van de config
 * @param config
 */
function initConfig(config: localConfig)
{
    console.log(`SihW-ng library (c) 2019 Spin in het Web B.V., Apeldoorn, The Netherlands.`);
    return () => config.init();
}

@NgModule({
    declarations: [],
    imports: [
        HttpClientModule
    ],
    providers: [
        localConfig,
        { provide: APP_INITIALIZER, useFactory: initConfig, deps: [localConfig], multi:true}
    ]
}) export class LocalconfigModule{}
