/**
 * Base class voor SpinJS2 en SpinJS2Rest.
 * (c) 2018 Jellybean BV Apeldoorn Netherlands
 */

import {localConfig} from "../localConfig/localconfig";
import {Sihwlog, Sihwlogger} from "../sihwlog/sihwlog";
import {Subject, Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Injector} from "@angular/core";

export abstract class SpinJS2Base {

    //dependencies via injector ivm overerving
    private Sihwlog: Sihwlog; //we gebruiken deze voor this.log, dus deze kan private blijven
    protected conf: localConfig;
    protected http: HttpClient;

    protected log: Sihwlogger;
    protected readonly _url: string;
    protected whenConnectedResolvers: Function[] = [];

    protected auth = {
        storekey: <string> "",
        persistence: <string> "",
        store: <Object> {}
    };

    //Subjects
    protected internalEvents = new Subject<{ event: string, data?: any }>(); //emit bij connect etc
    protected backendNotify = new Subject<{ msg: string, data?: any }>(); //emit bij backend-notify (voor zover mogelijk)


    protected constructor(injector: Injector) {
        //set de injectables expliciet

        this.Sihwlog = injector.get(Sihwlog);
        this.conf = injector.get(localConfig);
        this.http = injector.get(HttpClient);

        this.log = this.Sihwlog.logger(this.conf.$('SIHW.SpinJS2.logLevel', 'warn'));
        console.log(`SpinJS2 Angular.io connector (c) 2018 Jellybean BV Apeldoorn NL`);
        this._url = this.conf.$('SIHW.SpinJS2.url');
        if (!this._url) {
            this.log.error(`SpinJS2: geen url geconfigureerd in localconfig SIHW.SpinJS2.url - stop`);
            return;
        }


        this.log.debug(`SpinJS2 - connect met ${this._url}`);

        //auth
        this.auth.storekey = this.conf.$('SIHW.SpinJS2.storeKey', 'SpinJS2');
        this.auth.persistence = this.conf.$('SIHW.SpinJS2.authPersistence', 'memory');
        switch (this.auth.persistence) {
            case 'session':
                this.auth.store = window.sessionStorage || {}; //fallback
                break;
            case 'storage':
                this.auth.store = (window.localStorage) || (window.sessionStorage) || {}; //fallbacks
                break;
            case 'memory':
            default:
                this.auth.store = {};
                break;
        }
        this.log.debug(`SpinJS2 storage`, this.auth.store === window.localStorage ? "Local" : (this.auth.store === window.sessionStorage ? "Session" : "Mem"));

    }

    /**
     * Stel een nieuwe authkey in voor gebruik vanaf nu. Deze key wordt ook opgeslagen in de authstorage
     * @param authkey
     */
    public set authKey(authkey: string | null) {
        if (authkey) {
            this.auth.store[this.auth.storekey] = authkey;
        }
        else {
            delete this.auth.store[this.auth.storekey];
        }
    }


    /**
     * Geeft de huidige authkey terug, of false als die er niet is
     */
    public get authKey(): string {
        return this.auth.store[this.auth.storekey] || false;
    }

    public get hasAuth(): boolean {
        return !!this.authKey;
    }

    public get url(): string {
        return this._url;
    }


    /**
     * Maak een complete get-url met de gegeven argumenten en eventueel een authkey
     * @param controller
     * @param actie
     * @param args Argumentenarray, voeg eventueel een extra arg toe om een mooie titel in een browserscherm te krijgen
     * @param [metAuth]
     */
    public maakGetUrl(controller: string, actie: string, args: string[], metAuth?: boolean): string {
        let volUrl = this._url;
        if (volUrl[volUrl.length - 1] !== '/') {
            volUrl += '/';
        }
        volUrl += `${controller}/${actie}`;
        if (metAuth) {
            volUrl += '/' + encodeURIComponent(`a=${this.authKey || ''}`);
        }
        volUrl += args.map(arg => `/${encodeURIComponent(arg)}`).join('');
        return volUrl;
    }


    /**
     * Doe een ping-request volgens het spinjs-protocol. Return de promise die dus zal resolven of rejecten
     * Via WS in de normale SpinJS2 lib, via post in de Rest-variant
     */
    public ping(): Promise<any> {
        return this.send('spinjs2', 'ping', {});
    }


    /**
     * Geef een promise die resolvet als we verbinding (her)krijgen met het backend
     * @return {*}
     */
    public whenConnected(): Promise<void> {
        if (this.connected) {
            return Promise.resolve(); //meteen al klaar, want we zijn nu verbonden
        }
        this.log.debug('whenConnected geeft promise terug die nog niet resolved is');
        return new Promise(resolve => {
            this.whenConnectedResolvers.push(resolve)
        });
    }

    /**
     * Geef terug of we connected zijn. Te implementeren door subclasses
     */
    public abstract get connected(): boolean;

    /**
     * Subscribe op interne events (close etc)
     * @param observer
     */
    public onInternal(observer: any): Subscription {
        return this.internalEvents.subscribe(observer);
    };

    /**
     * Subscribe op backend notificaties (let op: is een noop bij rest: nooit een event)
     * @param observer
     */
    public onNotify(observer: any): Subscription {
        return this.backendNotify.subscribe(observer);
    }

    /**
     * Communiceer met het SpinJS2 Backend, via WS (SpinJS2) of Post (SpinJS2 / SpinJS2Rest)
     * @param controller
     * @param action
     * @param data
     */
    public abstract send(controller: string, action: string, data?: { [key: string]: any });

    /**
     * Communiceer via POST met het backend (beschikbaar in SpinJS2rest en in SpinJS2)
     * @param controller
     * @param action
     * @param data
     */
    public sendRest(controller: string, action: string, data?: { [key: string]: any }): Promise<any> {
        data = data || {};

        //speciaal: als data._spinjs2_slowdebug een waarde heeft, dan is dat een timeout voor deze send
        //om slome lijnen te testen

        return new Promise<any>((resolve, reject) => {
            if (!this._url) {
                return reject("Geen url");
            }

            if (data._spinjs2_slowdebug) {
                let vertraging = data._spinjs2_slowdebug;
                delete data._spinjs2_slowdebug;
                this.log.warn("SPINJS2 SLOW DEBUG: send met vertraging", vertraging, controller, action);
                setTimeout(() => {
                    this.sendRest(controller, action, data).then(resolve, reject);
                }, vertraging);
                return;
            }

            let auth = this.authKey;
            this.log.debug(`SpinJS2: POST => ${controller}.${action} ${(!!auth) ? 'auth' : 'noauth'}`, data);
            let body: { data: any, auth?: string } = {data: data};
            if (auth) {
                body.auth = auth;
            }
            this.http.post(`${this._url}/${controller}/${action}`, body, {reportProgress: false}).subscribe(
                (rdata: any) => {
                    this.log.debug('SpinJS2: RESULTAAT <= ' + controller + '.' + action, rdata);
                    try {
                        if (rdata._newauth) {
                            //alleen als we er al eentje hadden (opnieuw checken, want async)
                            if (this.hasAuth) {
                                this.log.debug('SPINJS2 we hebben een nieuw auth');
                                this.authKey = rdata._newauth; //dit is de nieuwe
                                delete rdata._newauth;
                            }
                            else {
                                this.log.warn('SPINJS2 we krijgen een nieuwe auth, maar hadden er geen');
                            }
                        }
                    }
                    catch (_e) {
                        //blijkbaar is rdata geen object? Whatever
                        this.log.debug('SPINJS2 reply kon niet gecheckt worden op _newauth');
                    }
                    resolve(rdata); //teruggeven
                }, err => {
                    let fout = (err.error || err.statusText || "error");
                    this.log.warn(`SpinJS2: Error <= ${controller}.${action}`, fout);
                    reject(fout);
                }
            );
        });
    }

    /**
     * Helper voor whenConnected:
     */
    protected resolveWhenConnected(): void {
        for (let F of this.whenConnectedResolvers
            ) {
            F();
        }
        this.whenConnectedResolvers = []; //en weer leeg
    }
}