/*
Module voor sihwlog
 */

import {Sihwlog} from "./sihwlog";
import {NgModule} from "@angular/core";
import {LocalconfigModule} from "../localConfig/localconfig.module";

@NgModule({
    declarations: [],
    imports: [
        LocalconfigModule
    ],
    providers: [
        Sihwlog
    ]
}) export class SihwlogModule{}