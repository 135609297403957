/*
localConfig service: leest /assets/localconfig.json en maakt deze beschikbaar
 */

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class localConfig {
    static url = "assets/localconfig.json"; //zonder voorslash want tov index
    private static content: any = {};

    constructor(private http: HttpClient) {
    }

    /**
     * Aangeroepen tijdens de init-app hook: lees de config in. Static zodat deze service ook in shared directives enzo herladen kan worden
     */
    async init() {
        let nieuwcontent: Object;

        if (localConfig.content && localConfig.content._loaded)
        {
            console.warn(`Localconfig dubbele init!`);
        }
        try {

            nieuwcontent = await this.http.get(localConfig.url).toPromise();
            localConfig.content = nieuwcontent;
            localConfig.content._loaded = true;
            return true;
        }
        catch (e) {
            console.error(`Fout bij laden configuratie uit ${localConfig.url}.`);
            return false;
        }
    }

    private get content() {
        return localConfig.content;
    }


    /**
     * Geef een waarde terug volgens een key.subkey.key3 pad. Als niet gevonden: geef een opgegeven standaard terug
     * @param path
     * @param standaard
     */
    public $(path: string, standaard: any = null): any {
        let level = this.content;
        for(let stap of path.split('.'))
        {
            if (stap in level)
            {
                level = level[stap];
            }
            else {
                return standaard;
            }
        }
        return level;
    }
}