import {localConfig} from "../localConfig/localconfig";
import {Injectable} from "@angular/core";

//een uiteindelijk loggerobject
export interface Sihwlogger {
    debug: Function;
    log: Function;
    info: Function;
    warn: Function;
    error: Function;
}

//voor het gemak gewoon een serverclass met de functie logger om een Sihwlogger te krijgen

@Injectable()
export class Sihwlog {
    private levels = {
        'debug': 1,
        'log': 2,
        'info': 3,
        'warn': 4,
        'error': 5
    };


    constructor(private conf: localConfig) {
        //config: sihwlog.logging: boolean (standaard false)
        //en sihwlog.minLevel: (standaard 'debug')

    }

    /**
     * Geef een logger object terug op het gegeven level
     * @param loglevel
     */
    logger(loglevel?: string): Sihwlogger {
        let noop = function () {
        };
        let logger: Sihwlogger = {
            debug: noop,
            log: noop,
            info: noop,
            warn: noop,
            error: noop
        };

        loglevel = loglevel || this.conf.$('SIHW.sihwlog.defaultLevel','warn');

        if (!(loglevel in this.levels)) {
            throw(`sihwlog: onbekend loglevel ${loglevel}`);
        }
        let levelnr = this.levels[loglevel];
        let enabled = this.conf.$('SIHW.sihwlog.logging', true);
        let minLevelnr = this.levels[this.conf.$('SIHW.sihwlog.minLevel', 'warn')] || 4;
        for (let level of Object.keys(this.levels)) {
            if (enabled && this.levels[level] >= minLevelnr && this.levels[level] >= levelnr) {
                logger[level] = level === 'debug' ? console.log.bind(console, '[DEBUG]') : console[level].bind(console);
            }
        }
        return logger;
    }
}
