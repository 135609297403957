/*
 Aparte module voor SpinJS2 met WS, zodat alleen wordt geïmporteerd wat nodig is
 */

import {NgModule} from "@angular/core";
import {SpinJS2} from "./spinjs2";
import {SihwlogModule} from "../sihwlog/sihwlog.module";

/**
 * Fix socket.io global
 */

@NgModule({
    declarations: [],
    imports: [
        SihwlogModule
    ],
    providers: [
        SpinJS2
    ]
}) export class Spinjs2Module{}
