import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Sihwlog, Sihwlogger} from '../../sihw-ng/sihwlog/sihwlog';
import {ModalDirective} from 'ngx-bootstrap';

@Component({
    selector: 'app-biertypes',
    templateUrl: './biertypes.component.html',
    styleUrls: ['./biertypes.component.scss']
})
export class BiertypesComponent implements OnInit {
    @ViewChild('editModal') editModal: ModalDirective;
    private log: Sihwlogger;
    data: any[] = [];
    edit: any = null;
    editMsg: string = null;

    constructor(public api: ApiService, Sihwlog: Sihwlog) {
        this.log = Sihwlog.logger('debug');
    }

    async ngOnInit() {
        await this.haalData();
    }

    /**
     * Haal de biertypes
     */
    async haalData(): Promise<void> {
        this.data = await this.api.biertypes();
    }

    editRij(rij) {
        this.log.debug(rij);
        this.edit = Object.assign({},rij); //deze editen we
        this.editModal.show();
    }

    /**
     * Save de geeditte rij. Data is al gecheckt via ux
     */
    async saveEdit() {
        try {
            await this.api.saveBiertype(this.edit);
            void this.haalData();
            this.editModal.hide();

        } catch (e) {
            this.log.error(e);
            this.editMsg = e.apiMsg || 'Interne fout';
        }


    }
}
