import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SplashComponent} from './splash/splash.component';
import {LoginComponent} from './login/login.component';
import {MainComponent} from './main/main.component';
import {PrivatePage, PublicOnly} from './PrivatePublicGuards';
import {BiertypesComponent} from './biertypes/biertypes.component';

const routes: Routes = [
    {
        // default:
        path: '', redirectTo: '/splash', pathMatch: 'full'
    },
    {
        path: 'splash', component: SplashComponent
    },
    {
        path: 'login', component: LoginComponent,
        canActivate: [PublicOnly]
    },
    {
        path: 'main', component: MainComponent,
        canActivate: [PrivatePage]
    },
    {
        path: 'biertypes', component: BiertypesComponent,
        canActivate: [PrivatePage]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
