/**
 * SpinJs2 backend Connector voor Angular.io / Ionic
 * (c) 2018 Jellybean BV Apeldoorn Netherlands
 *
 */


import {Injectable, Injector} from "@angular/core";
import io from "socket.io-client";
import {SpinJS2Base} from "./spinjs2base";

@Injectable()
export class SpinJS2 extends SpinJS2Base {
    private socket; //onze io-socket

    constructor(injector: Injector) {
        super(injector);

        this.initSocket();
        this.log.debug('spinJS2 init compleet.');
    }

    /**
     * Zijn we op dit moment verbonden?
     */
    public get connected(): boolean {
        return this.socket && this.socket.connected;
    }


    /**
     * Communiceer met het SpinJS2 Backend, via WS of Post
     * @param controller
     * @param action
     * @param data
     * @param usePost gebruik post ipv ws hebben
     */

    public send(controller: string, action: string, data?: { [key: string]: any }, usePost?: boolean): Promise<any> {
        if (usePost) {
            //dat kan onze super
            return this.sendRest(controller, action, data);
        }

        data = data || {};

        //speciaal: als data._spinjs2_slowdebug een waarde heeft, dan is dat een timeout voor deze send
        //om slome lijnen te testen

        return new Promise<any>((resolve, reject) => {
            if (!this._url) {
                return reject("Geen url");
            }

            if (data._spinjs2_slowdebug) {
                let vertraging = data._spinjs2_slowdebug;
                delete data._spinjs2_slowdebug;
                this.log.warn("SPINJS2 SLOW DEBUG: send met vertraging", vertraging, controller, action);
                setTimeout(() => {
                    this.send(controller, action, data).then(resolve, reject);
                }, vertraging);
                return;
            }

            let emitstate = 'init'; //we houden het een en ander bij

            //regel een timeout als de socket (nog) niet connected is
            if (!this.socket.connected) {
                //kijk of een tijdje hoe we er voor staan
                this.log.info('SPINJS2: socket nog niet verbonden tijdens send. We sturen het bericht, maar kijken zo of de socket er al is');
                setTimeout(() => {
                    //kijken of het na 10 seconden allemaal geregeld is
                    if (emitstate !== "antwoord") {
                        //dus er is ondertussen geen antwoord
                        emitstate = "skip";
                        this.log.warn("SPINJS2: Timeout connecting");
                        reject('timeout connecting');
                    }
                }, 10000);
            }

            let auth = this.authKey;
            this.log.debug(`SpinJS2: WS => ${controller}.${action} ${(!!auth) ? 'auth' : 'noauth'}`, data);
            let res = new Promise((resolve, reject) => {
                this.socket.emit('apireq',
                    {
                        controller: controller,
                        action: action,
                        auth: auth,
                        data: data
                    },
                    (resultaat, rdata) => {
                        if (resultaat) {
                            resolve(rdata);
                        } else {
                            reject(rdata);
                        }
                    })

            });

            emitstate = 'verzonden'; //wacht op antwoord
            res.then((rdata: any) => {

                if (emitstate === "skip") {
                    return; //promise al rejected door de timeout
                }
                emitstate = 'antwoord'; //we hebben antwoord
                this.log.debug('SpinJS2: RESULTAAT <= ' + controller + '.' + action, rdata);
                try {
                    if (rdata._newauth) {
                        //alleen als we er al eentje hadden (opnieuw checken, want async)
                        if (this.hasAuth) {
                            this.log.debug('SPINJS2 we hebben een nieuw auth');
                            this.authKey = rdata._newauth; //dit is de nieuwe
                            delete rdata._newauth;
                        } else {
                            this.log.warn('SPINJS2 we krijgen een nieuwe auth, maar hadden er geen');
                        }
                    }
                } catch (_e) {
                    //blijkbaar is rdata geen object? Whatever
                    this.log.debug('SPINJS2 reply kon niet gecheckt worden op _newauth');
                }
                resolve(rdata); //teruggeven
            })
                .catch(rdata => {
                    emitstate = 'antwoord'; //we hebben antwoord
                    this.log.warn(`SpinJS2: Error <= ${controller}.${action}`, rdata);
                    reject(rdata);
                });
        });
    }

    /**
     * Init de socket
     */
    private initSocket(): void {
        this.socket = io.connect(this._url);
        this.socket.on('connect', () => {
            this.log.debug('SpinJS2: connect', {url: this._url});
            this.internalEvents.next({event: 'connect'}); //broadcast
            this.resolveWhenConnected();
        });
        this.socket.on('reconnect', () => {
            this.log.debug('SpinJS2: reconnect');
            this.internalEvents.next({event: 'reconnect'}); //broadcast
            this.resolveWhenConnected();
        });
        this.socket.on('error', (...args) => {
            this.log.warn('SpinJS2: error', ...args);
            //we geven het door aan geïnteresseerden
            this.internalEvents.next({event: 'error', data: args}); //broadcast
        });
        this.socket.on('reconnect_failed', (...args) => {
            this.log.warn('SpinJS2: error', ...args);
            this.internalEvents.next({event: 'error', data: args}); //broadcast
        });
        this.socket.on('disconnect', (...args) => {
            this.log.debug('SpinJS2: close', ...args);
            this.internalEvents.next({event: 'close'}); //broadcast
        });
//notificatie door het backend
        this.socket.on('notify', notifydata => {
            this.log.debug('SpinJS2: backendNotify', notifydata);
            //stuur het de wereld in:
            this.backendNotify.next(notifydata)
        });
    }

}
