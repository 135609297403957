import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';
import {Sihwlog, Sihwlogger} from '../../sihw-ng/sihwlog/sihwlog';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginComponent} from '../login/login.component';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  private log: Sihwlogger;

  constructor(private route: ActivatedRoute, private router: Router, public api: ApiService, Sihwlog: Sihwlog) {
    this.log = Sihwlog.logger('debug');
  }

  ngOnInit() {
    //even wachten, zodat we niet 2 routes tegelijk navigeren
    setTimeout(() => {
      void this.checkNextPage()
    }, 100);
  }

  /**
   * Waar gaan we heen?
   */
  private async checkNextPage(): Promise<void> {
    await this.api.afterInit();
    this.log.debug(`Splash: api is er klaar voor`);
    if (await this.api.loggedIn())
    {
      this.log.debug(`Splash: we zijn ingelogd en gaan naar main`);
      void this.router.navigate(['main']);
    }
    else {
      this.log.debug(`Splash: naar login`);
      void this.router.navigate(['login']);
    }
  }

}
