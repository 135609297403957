/**
 Guard voor inloggen:
 PrivatePage: check of iemand wel is ingelogd
 PublicOnly: check of iemand niet is ingelogd
 **/


import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {ApiService} from './services/api.service';

@Injectable({
    providedIn: 'root'
})
export class PrivatePage implements CanActivate {
    constructor(private router: Router, private  api: ApiService) {
    }

    async canActivate(route: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        //niet als we al zijn ingelogd
        return (await this.api.loggedIn()) || this.router.parseUrl('/splash');
    }
}

@Injectable({
    providedIn: 'root'
})
export class PublicOnly implements CanActivate {
    constructor(private router: Router, private  api: ApiService) {
    }

    async canActivate(route: ActivatedRouteSnapshot,
                      state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        //niet als we al zijn ingelogd
        return (!await this.api.loggedIn()) || this.router.parseUrl('/splash');
    }
}

