import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SplashComponent} from './splash/splash.component';
import {SihwlogModule} from '../sihw-ng/sihwlog/sihwlog.module';
import {Spinjs2Module} from '../sihw-ng/spinjs2/spinjs2.module';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainComponent} from './main/main.component';
import { BiertypesComponent } from './biertypes/biertypes.component';
import {SihwStickyheaderModule} from '../sihw-ng/stickyheader/stickyheader.module';
import {ModalModule} from 'ngx-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        SplashComponent,
        LoginComponent,
        MainComponent,
        BiertypesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SihwlogModule,
        Spinjs2Module,
        SihwStickyheaderModule,
        ModalModule.forRoot()
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
