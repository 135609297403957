import {NgModule} from '@angular/core';
import {SihwStickyheader} from './stickyheader';

@NgModule({
    declarations: [
        SihwStickyheader
    ],
    exports: [
        SihwStickyheader
    ]
}) export class SihwStickyheaderModule {}
