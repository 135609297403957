import {Injectable} from '@angular/core';
import {Sihwlog, Sihwlogger} from '../../sihw-ng/sihwlog/sihwlog';
import {SpinJS2} from '../../sihw-ng/spinjs2/spinjs2';
import * as moment from 'moment';

//we hebben hier een apiversion. Die staat los van de officiele appversion in de stores
const apiversion = 0.04;

interface admindata {
    id: number,
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private log: Sihwlogger;

    private _besettings: any = {}; //door het backend meegestuurde settings
    private _admindata: admindata = null; //de userdata vanuit het backend
    private cache = new Map(); //interne memcache voor bepaalde dingen. Zie getCache en setCache

    private readonly cacheDuration = {minutes: 60}; //moment add/subtract object

    constructor(private SpinJS2: SpinJS2, sihwLog: Sihwlog) {
        this.log = sihwLog.logger('debug');
        this.log.debug(`API geladen`);
    }

    /**
     * Resolve een promise als we veronden zijn
     */
    public afterInit(): Promise<void> {
        return this.SpinJS2.whenConnected();
    }

    /**
     * login. Als dit lukt wordt de admindata gezet. Exception-proof. Returnt een lege string als alles okee, of een foutcode bij problemen!!
     * @param username
     * @param password
     * @returns Een lege string als alles ok! Of een foutcode bij problemen
     */
    public async login(username: string, password: string): Promise<string> {
        this.logout(); //uitloggen
        let data = {
            login: username,
            wachtwoord: password
        };

        try {

            let res = await this.send('admin', 'login', data);
            //gelukt? -- we krijgen een webtoken en data
            this.log.debug('inloggen gelukt', res);
            this._updateLogindata(res);
            return "";
        } catch (err) {
            this.log.debug('err bij login', err);
            return err.code || "INTERNAL_ERROR";
        }
    }


    /**
     * Geef een boolean die aangeeft of we ingelogd (lijken te) zijn
     *
     */
    public async loggedIn(): Promise<boolean> {
        if (!
            this.SpinJS2.hasAuth
        ) {
            //gaat hem niet worden
            this.log.debug(`Api: niet ingelogd (auth ontbreekt)`);
            return false;
        }

        if (this._admindata) {
            //dat ziet er goed genoeg uit
            this.log.debug(`Api: ingelogd (data)`);
            return true;
        }

        //wel een auth, nog geen data. We zullen het moeten vragen
        try {
            let res = await this.send('admin', 'relogin');
            this.log.debug(`Api: ingelogd (backend)`, res);
            this._updateLogindata(res);

            return true;
        } catch (e) {
            this.log.debug(e);
            this.logout();
            this.log.debug(`Api: niet ingelogd (auth geweigerd)`);
            return false;
        }
    }

    /**
     * sync versie van logincheck. We kijken puur naar onze flag
     */
    public get isLoggedIn() {
        return this.SpinJS2.hasAuth && (!!this._admindata);
    }

    /**
     * Sla de teruggekomen logindata op
     * @param data
     * @private
     */
    private _updateLogindata(data: { authkey: string, admindata: admindata, besettings: any }) {
        this.SpinJS2.authKey = data.authkey; //altijd een nieuwe
        this._admindata = data.admindata; //hopla
        this._besettings = data.besettings; //idem
    }

    /**
     * Clear alle data
     */
    public logout() {
        this.SpinJS2.authKey = null;
        this._admindata = null;
        //TODO: emit?
        this.cache.clear(); //leeg
    }

    //////////////////////////////// wrappers //////////////////////////
    public async biertypes(): Promise<any[]>
    {
        try {
            let res = await this.send("admin", "biertypes");
            return res.data;
        }
        catch(e)
        {
            this.log.error(e);
            return [];
        }
    }

    /**
     * Save een bestaand of nieuw biertype. Throwt error met .apiMsg als nodig
     * @param data
     */
    public async saveBiertype(data): Promise<void>
    {
        return this.send('admin','savebiertype',{data: data});
    }

    //////////////////////////// intern ////////////////////////////////



    /**
     * Interne verzender. Voegt devicekey toe en vangt SPINJS_INVALID_TOKEN op bij verlopen auth
     * @param controller
     * @param action
     * @param data
     */
    private async send(controller: string, action: string, data: any = {}): Promise<any> {
        //specials die we altijd meesturen tenzij overruled
        let specials = {
            _v: apiversion
        };
        for (let special of Object.keys(specials)) {
            if (!(special in data)) {
                data[special] = specials[special]
            }
        }

        try {
            let res = await this.SpinJS2.send(controller, action, data);  //als het goed gaat, gewoon teruggeven
            return res;
        } catch (err) {
            if (err && err.code === "SPINJS_INVALID_TOKEN") {
                //we gaan uitloggen
                //TODO: routeren of emitten?
                this.logout();
            }
            //rethrow
            throw(err);
        }
    }

    //// cache. Is vooral bedoeld voor resultaten van het backend.
    /// we slaan het daarom op als json, zodat we altijd shallow copies hebben
    /**
     * Check in onze memcache op de gegeven sleutel, en of deze nog vers is
     * d is een ding waarvan we een shallow copy maken
     * @param key
     * @param sub subkey, verplicht. Any.
     */
    private getCache(key: string, sub: any): any | false {
        sub = sub.toString();
        let cached = this.cache.has(key) && this.cache.get(key).get(sub);
        if (cached) {
            if (cached.m.isAfter(moment().subtract(this.cacheDuration))) {
                //nog vers. Lever een shallow copy
                try {
                    return JSON.parse(cached.d)
                } catch (e) {
                    this.log.error(e);
                }
            }
            //weg ermee, het is niet meer vers
            this.cache.get(key).delete(sub);
        }
        return false;
    }

    private setCache(key: string, sub: any, data: any): void {
        try {
            let submap = this.cache.get(key);
            if (!submap) {
                submap = new Map();
                this.cache.set(key, submap);
            }
            submap.set(sub, {
                m: moment(),
                d: JSON.stringify(data)
            });
        } catch (_e) {
        }
    }

    /**
     * Verwijder een cache, met één subkey of alles
     * @param key
     * @param [sub] Als niet gegeven, dan wordt de hele cache voor de hoofdkey verwijderd
     */
    private deleteCache(key: string, sub?: any): void {
        this.log.debug(`Delete cache`, key, sub);
        if (sub) {
            let submap = this.cache.get(key);
            if (submap)
            {
                submap.delete(sub);
            }
        } else {
            this.cache.delete(key);
        }
    }


}
