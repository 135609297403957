import {Directive, ElementRef, HostListener} from '@angular/core';
import * as $ from 'jquery';

//requires jquery

@Directive({
    selector: '[sihwstickyheader]'
})
export class SihwStickyheader {
    jhead: any;

    constructor(private el: ElementRef) {
    }

    @HostListener('scroll') onScroll() {
        if (!this.jhead) {
            this.jhead = $(this.el.nativeElement).find('thead');
        }
        if (this.el.nativeElement.scrollTop) {
            this.jhead.css({transform: `translateY(${this.el.nativeElement.scrollTop}px)`});
        } else {
            this.jhead.css({transform: ''}); //weg
        }
    }
}
