import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Sihwlog, Sihwlogger} from '../../sihw-ng/sihwlog/sihwlog';
import { Router} from '@angular/router';
import {ApiService} from '../services/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    formfeedback = '';
    loginForm = new FormGroup({
        email: new FormControl(),
        wachtwoord: new FormControl()
    });
    private log: Sihwlogger;


    constructor(private router: Router, public api: ApiService, Sihwlog: Sihwlog) {
        this.log = Sihwlog.logger('debug');
        this.log.debug(`Create Login`);
    }

    ngOnInit() {

    }

    async login() {
        this.formfeedback = 'Inloggen...';
        let data = this.loginForm.value;
        let errcode = await this.api.login(data.email, data.wachtwoord);
        this.log.debug('errcode', errcode);
        if (!errcode) {
            //gelukt!
            void this.router.navigate(['splash']); //splash mag regelen waar we heen gaan
        } else {
            //mislukt
            this.formfeedback = errcode === 'NO_AUTH' ? 'invalid' : 'error';
        }
    }

}
